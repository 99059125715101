#toast {
    display: none;
    position: fixed;
    width: 800px;
    bottom: 100px;
    left: 50%;
    margin-left: -400px
}
#toast.is-visible {
    display: block
}
.box.is-overflow {
    overflow-y: auto
}
.is-linewrap {
    white-space: pre-line
}
span.new {
    display: inline-block;
    color: white;
    background-color: #00b89c;
    padding: 5px 8px;
    font-size: 16px;
    border-radius: 5px
}
.as_link {
    color: #3273dc !important;
    cursor: pointer
}
.as_link_padding {
    padding: 0 0.75em
}
.img-draggable {
    height: 180px ;
    width: 100% ;
    object-fit: contain
}
.is-section-dragging {
    height: 300px;
    overflow: hidden
}
.cursor-pointer{
    cursor: pointer
}